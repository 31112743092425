var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('paper',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.btnloading),expression:"btnloading"}],staticClass:"mip-iframe"},[_c('div',{staticClass:"top-box"},[_c('div',{staticClass:"header-box"},[_c('h4',[_vm._v("战合客户引入申请")]),_c('div',{staticClass:"header-info-crm"},[_c('div',{staticClass:"tips"},[_c('div',[_vm._v("战略合作类型说明")]),_c('div',[_vm._v(_vm._s(_vm.detailDataLocal.accessWarn))])]),_c('div',{staticClass:"apply-info"},[_c('div',[_vm._v("申请人："+_vm._s(_vm.detailDataLocal.applicantName))]),_c('div',[_vm._v("部门："+_vm._s(_vm.detailDataLocal.applicantDepartName))])])])]),_c('div',{staticClass:"detail-toolBar"},[(!_vm.readonly && ![2, 3, 4, 7].includes(+_vm.detailDataLocal.mipStatus))?_c('lots-button',{attrs:{"type":"primary","loading":_vm.saveLoading},on:{"click":_vm.handleSave}},[_vm._v("保存 ")]):_vm._e(),(_vm.readonly && ![2, 3, 4, 7].includes(+_vm.detailDataLocal.mipStatus))?_c('lots-button',{attrs:{"type":"primary"},on:{"click":_vm.handleEdit}},[_vm._v("编辑 ")]):_vm._e(),_c('el-tooltip',{staticClass:"item-flow-right",attrs:{"effect":"dark","content":"刷新","placement":"bottom"}},[_c('lots-button',{staticClass:"refresh",attrs:{"size":"mini","icon":"el-icon-refresh","loading":_vm.detailLoading},on:{"click":function($event){return _vm.refleshPage()}}})],1)],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.detailLoading),expression:"detailLoading"}],staticClass:"strategic-cooperation-introduce-apply"},[_c('collapse-infos',{ref:"upholdHealder",attrs:{"detailData":_vm.detailDataLocal,"collapse":_vm.COLLAPSE,"activeCollapses":_vm.activeCollapses},on:{"change":_vm.handleInfosChange},scopedSlots:_vm._u([{key:"collapse-title-append",fn:function(ref){
var collapseItem = ref.collapseItem;
return [(collapseItem.formName === 'qingshiInfo')?_c('span',[_vm._v(" "+_vm._s(collapseItem.title)+" "),_c('el-tooltip',{attrs:{"effect":"dark","placement":"top"}},[_c('i',{staticClass:"el-icon-question"}),_c('template',{slot:"content"},[_c('div',{staticClass:"my-tooltip-box",domProps:{"innerHTML":_vm._s(_vm.detailDataLocal.requestContentHelp
                            ? _vm.detailDataLocal.requestContentHelp.replace(/\n/g, '<br>') : '--')}})])],2)],1):_c('span',[_vm._v(_vm._s(collapseItem.title))])]}},{key:"collapse-append",fn:function(ref){
                            var form = ref.form;
                            var itemSetting = ref.itemSetting;
                            var rIndex = ref.rIndex;
                            var collapseItem = ref.collapseItem;
                            var dictData = ref.dictData;
return [(itemSetting.prop === 'relatedFiles')?_c('span',[_c('lots-upload-button',{attrs:{"text":'上传附件',"bucket":'annto-lcrm',"disabled":_vm.readonly},on:{"callback":function (data) { _vm.upLoadData(form, itemSetting, data); }}}),_c('div',{staticClass:"file-infos"},[(form[itemSetting.prop]
                            && form[itemSetting.prop].length)?_vm._l((form[itemSetting.prop]),function(item,index){return _c('div',{key:index + item.fileName,staticClass:"file-list"},[_c('div',{staticClass:"file-name upload",attrs:{"title":item.fileName}},[_vm._v(" "+_vm._s(((index+1) + ". "))+" "),_c('a',{attrs:{"href":item.fileUrl,"target":"_blank"}},[_vm._v(_vm._s(item.fileName))])]),_c('div',{staticClass:"delete-btn"},[_c('el-button',{attrs:{"type":"text","plain":""}},[_c('a',{attrs:{"href":item.fileUrl,"target":"_blank"}},[_vm._v("下载")])]),_c('el-button',{attrs:{"type":"text","disabled":_vm.readonly,"plain":""},on:{"click":function($event){return _vm.handleDeleteFile(form, itemSetting, index)}}},[_vm._v("删除")])],1)])}):_vm._e()],2)],1):(['salesChannelsList', 'categoryStructureList', 'proxyBrandList'].includes(itemSetting.prop))?_c('div',{class:{
                        'width-small': itemSetting.prop === 'salesChannelsList',
                        'width-middle': itemSetting.prop === 'categoryStructureList',
                        'width-large': itemSetting.prop === 'proxyBrandList'
                    }},[_c('el-table',{attrs:{"border":true,"data":_vm.tableData(itemSetting.prop),"height":_vm.tableHeight(itemSetting.prop)}},_vm._l((itemSetting.columns),function(col,cIndex){return _c('el-table-column',_vm._b({key:col.prop + cIndex,attrs:{"prop":col.prop,"label":col.label,"show-overflow-tooltip":_vm.lodash.get(col, 'showTooltip', true)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
                    var rIndex = ref.$index;
return [(col.type === 'select')?_c('div',[(dictData[col.optionsKey] && row[col.prop])?_c('span',[_vm._v(_vm._s(dictData[col.optionsKey][row[col.prop]] || row[col.prop] ||''))]):_vm._e()]):_c('div',[_vm._v(_vm._s(row[col.prop] || ''))])]}}],null,true)},'el-table-column',Object.assign({}, col, {type: _vm.lodash.get(col, 'columnType', '')}),false))}),1)],1):(itemSetting.prop === 'ratingScoreList')?_c('div',{staticClass:"rating-score-list-box"},_vm._l((_vm.newScoreConfig),function(scoreItem,index){return _c('div',{key:index,staticClass:"score-config-item-box"},[_c('div',{staticClass:"item-tittle"},[_vm._v(_vm._s(scoreItem.title)+"：")]),_c('div',{staticClass:"item-content"},[_c('dc-form',{ref:"scoreDcForm",refInFor:true,attrs:{"row":_vm.tableData(itemSetting.prop)[0],"config":scoreItem.formList,"col":scoreItem.col,"labelWidth":scoreItem.labelWidth},scopedSlots:_vm._u([{key:"form-append",fn:function(ref){
                    var form = ref.form;
                    var itemSetting = ref.itemSetting;
                    var rIndex = ref.rIndex;
return [_c('el-form-item',{attrs:{"label":itemSetting.label,"prop":itemSetting.prop,"label-width":scoreItem.labelWidth}},[_c('span',{staticClass:"custom-span",attrs:{"title":form[itemSetting.prop]}},[_vm._v(" "+_vm._s(form[itemSetting.prop])+" ")])])]}}],null,true)})],1)])}),0):(itemSetting.prop === 'referenceFile')?_c('span',[_c('div',{staticClass:"file-infos"},[(dictData[itemSetting.dictKey])?[_c('el-button',{attrs:{"type":"text","plain":""},on:{"click":function($event){return _vm.handleDownload('ALL', '参考附件模板.zip')}}},[_vm._v(" 下载全部 ")]),_vm._l((dictData[itemSetting.dictKey]),function(value,key,index){return _c('div',{key:key,staticClass:"file-list"},[_c('div',{staticClass:"file-name",attrs:{"title":value}},[_vm._v(" "+_vm._s(((index+1) + ". "))+" "+_vm._s(value)+" ")]),_c('div',{staticClass:"delete-btn"},[_c('el-button',{attrs:{"type":"text","plain":""},on:{"click":function($event){return _vm.handleDownload(key, value)}}},[_vm._v(" 下载 ")])],1)])})]:_vm._e()],2)]):(!itemSetting.optionsKey)?_c('span',{staticClass:"custom-span",attrs:{"title":form[itemSetting.prop]}},[_vm._v(" "+_vm._s((form[itemSetting.prop] || form[itemSetting.prop] === 0) ? form[itemSetting.prop] : '--')+" ")]):_vm._e()]}}])}),(_vm.detailDataLocal.mipFlowId)?_c('el-collapse',{staticClass:"contract-collapse detail-more-collapse",attrs:{"value":"mipInfo"}},[_c('el-collapse-item',{attrs:{"title":"准入审批","name":"mipInfo"}},[_c('div',{staticClass:"header-operation"},[(_vm.readonly)?_c('lots-button',{attrs:{"size":"mini","type":"primary","loading":_vm.btnloading},on:{"click":_vm.submitMip}},[_vm._v(" mip提交 ")]):_vm._e()],1),(!_vm.btnloading)?_c('iframe',{staticClass:"ifr",attrs:{"src":_vm.mipUrl}}):_vm._e()])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
/*eslint-disable*/
import store from '@/store';
const tenantCode = store.getters.currentTenant.tenantCode;
// 表单配置信息
export const collapse = [
    {
        title: '基本信息',
        ref: 'basicForm',
        formName: 'basicInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '客户名称',
                prop: 'customerName',
                value: 'customerName',
                type: 'custom',
            },
            {
                label: '法人',
                prop: 'legalPerson',
                value: 'legalPerson',
                type: 'custom'
            },
            {
                label: '客户系',
                prop: 'customerGroupName',
                value: 'customerGroupName',
                type: 'custom',
                // optionsKey: 'SYS_CUST_RELATION_TYPE'
            },
            {
                label: '行业类型',
                prop: 'customerIndustryType',
                value: 'customerIndustryType',
                type: 'custom',
                // optionsKey: 'SYS_WM_CUSTOMER_LEVEL'
            },
            {
                label: '营业收入(万)',
                prop: 'customerRevenue',
                value: 'customerRevenue',
                type: 'custom'
            },
            {
                label: '物流总费用(万)',
                prop: 'logisticsTotalCost',
                value: 'logisticsTotalCost',
                type: 'custom'
            },
            {
                label: '风险等级',
                prop: 'overallRiskLevel',
                value: 'overallRiskLevel',
                type: 'custom'
            },
            {
                label: '风险原因',
                prop: 'overallRiskInfo',
                value: 'overallRiskInfo',
                // type: 'custom',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 24,
                rows: 3,
                disabled: true
            },
            {
                label: '客户分类',
                prop: 'overallCustomerClassify',
                value: 'overallCustomerClassify',
                type: 'custom'
            },
            {
                label: '省',
                prop: 'fState',
                value: 'fState',
                type: 'custom'
            },
            {
                label: '市',
                prop: 'fCity',
                value: 'fCity',
                type: 'custom'
            },
            {
                label: '区',
                prop: 'fDistrict',
                value: 'fDistrict',
                type: 'custom'
            },
            {
                label: '客户地址',
                prop: 'address',
                value: 'address',
                type: 'custom'
            },
            {
                label: '开发单位',
                prop: 'dimDepart',
                value: 'dimDepart',
                type: 'custom'
            },
            {
                label: '所属片区',
                prop: 'area',
                value: 'area',
                type: 'custom'
            }
        ]
    },
    {
        title: '商机概况',
        ref: 'gaikuangForm',
        formName: 'gaikuangInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '商机名称',
                prop: 'opportunityName',
                value: 'opportunityName',
                type: 'custom',
            },
            {
                label: '战略合作类型',
                prop: 'teamType',
                value: 'teamType',
                type: 'custom'
            },
            {
                label: '联系人',
                prop: 'contact',
                value: 'contact',
                type: 'custom'
            },
            {
                label: '联系方式',
                prop: 'contactInformation',
                value: 'contactInformation',
                type: 'custom'
            },
            {
                label: '渠道类型',
                prop: 'salesChannels',
                value: 'salesChannels',
                type: 'custom'
            },
            {
                label: '终端数量',
                prop: 'coverStores',
                value: 'coverStores',
                type: 'custom'
            },
            {
                label: '品类结构',
                prop: 'categoryStructure',
                value: 'categoryStructure',
                type: 'custom'
            },
            {
                label: '开发人',
                prop: 'ownerName',
                value: 'ownerName',
                type: 'custom'
            },
            {
                label: '覆盖区域',
                prop: 'coverRegion',
                value: 'coverRegion',
                // type: 'custom',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 24,
                rows: 3,
                disabled: true
            },
            {
                label: '项目进展',
                prop: 'proDebriefing',
                value: 'proDebriefing',
                type: 'custom'
            }
        ]
    },
    {
        title: '使用系统',
        ref: 'shiyongForm',
        formName: 'shiyongInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: 'ERP',
                prop: 'erp',
                value: 'erp',
                type: 'custom',
            },
            {
                label: '商流系统',
                prop: 'businessSystem',
                value: 'businessSystem',
                type: 'custom'
            },
            {
                label: '物流系统',
                prop: 'logisticsSystem',
                value: 'logisticsSystem',
                type: 'custom'
            }
        ]
    },
    {
        title: '客户经营趋势描述',
        ref: 'kehuJingyingForm',
        formName: 'kehuJingyingInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '规模',
                prop: 'scale',
                value: 'scale',
                type: 'custom'
            },
            {
                label: '利润',
                prop: 'profit',
                value: 'profit',
                type: 'custom'
            },
            {
                label: '品类',
                prop: 'categoryStructurePlan',
                value: 'categoryStructurePlan',
                type: 'custom'
            },
            {
                label: '品牌',
                prop: 'brand',
                value: 'brand',
                type: 'custom'
            },
            {
                label: 'SKU',
                prop: 'sku',
                value: 'sku',
                type: 'custom'
            },
            {
                label: '渠道',
                prop: 'channels',
                value: 'channels',
                type: 'custom'
            },
            {
                label: '终端门店',
                prop: 'coverStoresPlan',
                value: 'coverStoresPlan',
                type: 'custom'
            }
        ]
    },
    {
        title: '客户转型方向描述',
        ref: 'kehuZhuanxingForm',
        formName: 'kehuZhuanxingInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '向上',
                prop: 'upPlan',
                value: 'upPlan',
                type: 'custom',
            },
            {
                label: '向下',
                prop: 'downPlan',
                value: 'downPlan',
                type: 'custom'
            },
            {
                label: '向前',
                prop: 'forwardPlan',
                value: 'forwardPlan',
                type: 'custom'
            },
            {
                label: '向后',
                prop: 'backwardPlan',
                value: 'backwardPlan',
                type: 'custom'
            },
            {
                label: '其他',
                prop: 'otherPlan',
                value: 'otherPlan',
                type: 'custom'
            }
        ]
    },
    {
        title: '客户合作价值点',
        ref: 'kehuHezuoForm',
        formName: 'kehuHezuoInfo',
        col: 4,
        labelWidth: '125px',
        visible: true,
        formList: [
            {
                label: '品牌新增长',
                prop: 'brandNewGrowth',
                value: 'brandNewGrowth',
                // type: 'custom',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 24,
                rows: 3,
                disabled: true
            },
            {
                label: '商流系统',
                prop: 'businessSystemPlan',
                value: 'businessSystemPlan',
                // type: 'custom',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 24,
                rows: 3,
                disabled: true
            },
            {
                label: 'B2B转型赋能',
                prop: 'b2BTransformation',
                value: 'b2BTransformation',
                // type: 'custom',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 24,
                rows: 3,
                disabled: true
            },
            {
                label: '新渠道代运营',
                prop: 'newChannelOperation',
                value: 'newChannelOperation',
                // type: 'custom',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 24,
                rows: 3,
                disabled: true
            },
            {
                label: 'C端运营',
                prop: 'cEndOperat',
                value: 'cEndOperat',
                // type: 'custom',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 24,
                rows: 3,
                disabled: true
            },
            {
                label: '物流系统',
                prop: 'logisticsSystemPlan',
                value: 'logisticsSystemPlan',
                // type: 'custom',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 24,
                rows: 3,
                disabled: true
            },
            {
                label: '物流专业化',
                prop: 'logisticsSpecialization',
                value: 'logisticsSpecialization',
                // type: 'custom',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 24,
                rows: 3,
                disabled: true
            },
            {
                label: '自有品牌拓展',
                prop: 'brandExpansion',
                value: 'brandExpansion',
                // type: 'custom',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 24,
                rows: 3,
                disabled: true
            },
            {
                label: '客情商机',
                prop: 'relationshipOppor',
                value: 'relationshipOppor',
                // type: 'custom',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 24,
                rows: 3,
                disabled: true
            }
        ]
    },
    {
        title: '评分评级',
        ref: 'pingfenForm',
        formName: 'pingfenInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '评级',
                prop: 'rating',
                value: 'rating',
                type: 'custom',
            },
            {
                label: '评分',
                prop: 'score',
                value: 'score',
                type: 'custom'
            }
            // {
            //     label: '评分表明细',
            //     prop: 'ratingScoreList',
            //     value: 'ratingScoreList',
            //     type: 'custom',
            //     span: 24
            // }
        ]
    },
    {
        title: '评分明细',
        ref: 'pingfenDetailForm',
        formName: 'pingfenDetailInfo',
        col: 4,
        labelWidth: '0px',
        visible: true,
        formList: [
            {
                label: '',
                prop: 'ratingScoreList',
                value: 'ratingScoreList',
                type: 'custom',
                span: 24
            }
        ]
    },
    {
        title: '销售渠道',
        ref: 'qudaoForm',
        formName: 'qudaoInfo',
        col: 4,
        labelWidth: '0px',
        visible: true,
        formList: [
            {
                label: '',
                prop: 'salesChannelsList',
                value: 'salesChannelsList',
                type: 'custom',
                span: 24,
                columns: [
                    {
                        label: '销售渠道',
                        minWidth: 160,
                        prop: 'salesChannels'
                    },
                    {
                        label: '覆盖终端门店数',
                        prop: 'coverStores',
                        minWidth: 160
                    }
                ]
            },
        ]
    },
    {
        title: '品类结构',
        ref: 'pinleiForm',
        formName: 'pinleiInfo',
        col: 4,
        labelWidth: '0px',
        visible: true,
        formList: [
            {
                label: '',
                prop: 'categoryStructureList',
                value: 'categoryStructureList',
                type: 'custom',
                span: 24,
                columns: [
                    {
                        label: '品类',
                        minWidth: 160,
                        prop: 'categoryStructure'
                    },
                    {
                        label: '年度规模(万)',
                        prop: 'annualScale',
                        minWidth: 160
                    },
                    {
                        label: '核心品牌',
                        prop: 'coreBrand',
                        minWidth: 160
                    }
                ]
            },
        ]
    },
    {
        title: '代理品牌',
        ref: 'dailiForm',
        formName: 'dailiInfo',
        col: 4,
        labelWidth: '0px',
        visible: true,
        formList: [
            {
                label: '',
                prop: 'proxyBrandList',
                value: 'proxyBrandList',
                type: 'custom',
                span: 24,
                columns: [
                    {
                        label: '品牌商',
                        prop: 'teamCustomerGroup',
                        minWidth: 160
                    },
                    {
                        label: '行业大类',
                        prop: 'industryCategories',
                        minWidth: 160
                    },
                    {
                        label: '代理层级',
                        prop: 'agentLevel',
                        minWidth: 160
                    },
                    {
                        label: '年度规模(万)',
                        prop: 'annualScale',
                        minWidth: 160
                    },
                    {
                        label: '代理开始时间',
                        prop: 'proxyStartTime',
                        minWidth: 160
                    },
                    {
                        label: '代理结束时间',
                        prop: 'proxyEndTime',
                        minWidth: 160
                    }
                ]
            },
        ]
    },
    {
        title: '请示内容',
        ref: 'qingshiForm',
        formName: 'qingshiInfo',
        col: 4,
        labelWidth: '20px',
        visible: true,
        formList: [
            {
                label: ' ',
                prop: 'requestContent',
                value: 'requestContent',
                // type: 'custom',
                // span: 24,
                sourceType: 'input',
                inputType: 'textarea',
                type: 'input',
                span: 23,
                // maxlength: 1024,
                rows: 10,
                showWordLimit: true,
                rule: [{ required: true, message: '请示内容不能为空', trigger: 'change' }],
                disabled: false
            },
        ]
    },
    {
        title: '相关附件',
        ref: 'relatedFileForm',
        formName: 'relatedFileInfo',
        col: 4,
        labelWidth: '80px',
        visible: true,
        formList: [
            {
                label: '附件',
                prop: 'relatedFiles',
                value: 'relatedFiles',
                type: 'custom',
                span: 23,
                rule: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (rule.required && !value.length) {
                                callback(new Error('相关附件不能为空'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    }
                ],
            },
        ]
    },
    {
        title: '参考附件',
        ref: 'cankaoFileForm',
        formName: 'cankaoFileInfo',
        col: 4,
        labelWidth: '0px',
        visible: true,
        formList: [
            {
                label: '',
                prop: 'referenceFile',
                value: 'referenceFile',
                type: 'custom',
                dictKey: 'CRM_REFERENCE_ATTACHMENT',
                span: 24,
            },
        ]
    },
    {
        title: '系统信息',
        ref: 'systemForm',
        formName: 'systemInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '商机编码',
                prop: 'opportunityCode',
                value: 'opportunityCode',
                type: 'custom'
            },
            {
                label: '审批状态（MIP）',
                prop: 'mipStatus',
                value: 'mipStatus',
                type: 'custom',
                optionsKey: 'SYS_SU_MIP_AUDIT_STATUS'
            },
            {
                label: '申请人',
                prop: 'applicantName',
                value: 'applicant',
                type: 'custom'
            },
            {
                label: '申请日期',
                prop: 'applicationDate',
                value: 'applicationDate',
                type: 'custom'
            },
            {
                label: '流程类型（MIP）',
                prop: 'mipType',
                value: 'mipType',
                optionsKey: 'MIP_TYPE',
                type: 'custom'
            },
            {
                label: '当前流程节点',
                prop: 'mipNode',
                value: 'mipNode',
                type: 'custom'
            },
            {
                label: '当前处理人',
                prop: 'handlePerson',
                value: 'handlePerson',
                type: 'custom'
            },
            {
                label: '审批通过日期',
                prop: 'approvedDate',
                value: 'approvedDate',
                type: 'custom'
            },
            {
                label: 'MIP流程ID',
                prop: 'mipFlowId',
                value: 'mipFlowId',
                type: 'custom'
            }
        ]
    }
];
// 评分明细配置
export const scoreConfig = [
    {
        title: '资质',
        formName: 'qualifications',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '成立年限',
                prop: 'establish',
                value: 'establish',
                type: 'custom',
            },
            {
                label: '操盘手',
                prop: 'trader',
                value: 'trader',
                type: 'custom',
            },
            {
                label: '推广团队',
                prop: 'marketingTeam',
                value: 'marketingTeam',
                type: 'custom',
            },
            {
                label: '办公环境',
                prop: 'officeEnvironment',
                value: 'officeEnvironment',
                type: 'custom',
            },
            {
                label: '近三年发展趋势',
                prop: 'pastThreeTrends',
                value: 'pastThreeTrends',
                type: 'custom',
            }
        ]
    },
    {
        title: '物流能力',
        formName: 'logisticsCapability',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '仓库空仓/扩容面积',
                prop: 'expansionArea',
                value: 'expansionArea',
                type: 'custom',
            },
            {
                label: '仓租成本竞争力',
                prop: 'whCompete',
                value: 'whCompete',
                type: 'custom',
            },
            {
                label: '车辆数',
                prop: 'vehicles',
                value: 'vehicles',
                type: 'custom',
            },
            {
                label: '配送成本竞争力',
                prop: 'deliveryCompete',
                value: 'deliveryCompete',
                type: 'custom',
            }
        ]
    },
    {
        title: '商流能力',
        formName: 'businessFlowCapability',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '商流规模',
                prop: 'businessScale',
                value: 'businessScale',
                type: 'custom',
            },
            {
                label: '运作品类',
                prop: 'operateCategory',
                value: 'operateCategory',
                type: 'custom',
            },
            {
                label: '运营品牌',
                prop: 'proxyBrand',
                value: 'proxyBrand',
                type: 'custom',
            },
            {
                label: '覆盖终端',
                prop: 'coverStores',
                value: 'coverStores',
                type: 'custom',
            },
            {
                label: '物流交付',
                prop: 'logisticsDelivery',
                value: 'logisticsDelivery',
                type: 'custom',
            },
            {
                label: '商品周转',
                prop: 'stockTurnover',
                value: 'stockTurnover',
                type: 'custom',
            }
        ]
    },
    {
        title: '转型',
        formName: 'transformation',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '向上（A类）',
                prop: 'up1',
                value: 'up1',
                type: 'custom',
            },
            {
                label: '向上（B/C类）',
                prop: 'up2',
                value: 'up2',
                type: 'custom',
            },
            {
                label: '向下',
                prop: 'down',
                value: 'down',
                type: 'custom',
            },
            {
                label: '向前',
                prop: 'forward',
                value: 'forward',
                type: 'custom',
            },
            {
                label: '向后',
                prop: 'backward',
                value: 'backward',
                type: 'custom',
            }
        ]
    },
    {
        title: '潜力',
        formName: 'potential',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '合仓意愿',
                prop: 'mergeWill',
                value: 'mergeWill',
                type: 'custom',
            },
            {
                label: '转型意愿',
                prop: 'transformWill',
                value: 'transformWill',
                type: 'custom',
            },
            {
                label: '行业地位',
                prop: 'industryStatus',
                value: 'industryStatus',
                type: 'custom',
            }
        ]
    }
];
